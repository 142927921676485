import React, { useState } from "react";
import { BounceLoader } from "react-spinners";
import { db } from '../firebase';
import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { UserAuth } from '../context/AuthContext';
import {Navbar} from '../components/Navbar';
import { render } from "@testing-library/react";
import { Navigate } from "react-router-dom";


 export const FormWizard = () => {
  const [prompt, setPrompt] = useState(" ");
  const [snippet, setSnippet] = React.useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { user } = UserAuth();

  const ENDPOINT =
    "https://funcapp-saebot.azurewebsites.net/GenerateFullBlogPost";
  const CODE = "HQgDYZ47kV9r1qI6FoB2lyC-S5cKsTH6u4ZwqSCc6DT3AzFufx9JOg==";


  const trackTextDBPath = doc(db, 'users', `${user?.email}`);
  const trackText = async (textIn,textOut) => {
      await updateDoc(trackTextDBPath, {
        watchList: arrayUnion({
          createdAt: new Date(),
          textInLength: textIn.length,
          textOutLength: textOut.length,
          textIn: textIn,
          textOut: textOut,
        }),
      });
    }




  const onSubmit = () => {
    console.log("Submitting: " + prompt);
    setIsLoading(true);
    fetch(`${ENDPOINT}?prompt=${prompt}&code=${CODE}`)
      .then((res) => res.json())
      .then(onResult);
  };

  const onResult = (data) => {
    setSnippet(data.snippet);
    setIsLoading(false);
    console.log(data.snippet)
    trackText(prompt,data.snippet);
    render(Navbar)
  };
  if (user) {

  return (
    <div className="max-w-[1140px] mx-auto">
      <div className="flex justify-center items-center my-12 py-8 border border-secondary rounded-2xl shadow-xl bg-primary px-2 max-w-[500px] w-full mx-auto  ">
        <form className="w-full max-w-sm">
          Lad os skrive
          <div className="flex items-center border-b border-teal-500 py-2">
            <input
              className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
              type="text"
              placeholder="Eks: de bedste rejser til Grækenland"
              onChange={(event) => setPrompt(event.target.value)}
            />
            <button
              className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
              type="button"
              onClick={onSubmit}

            >
              Lad os skrive!
            </button>

          </div>
        </form>

      </div>
      {isLoading ?      
      <div className="flex justify-center items-center my-12 py-8 border border-secondary rounded-2xl shadow-xl bg-primary px-2 max-w-[500px] w-full mx-auto  ">
<BounceLoader color="#36d7b7" /></div>:null}

      <div className="items-center my-12 py-8 rounded-div">
      <form name="form1">

        <div>
          <label
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
          >
            Your message
          </label>
          <textarea
        value={snippet}
        onChange={setSnippet}
            id="message"
            rows="100"
            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
        hej
          </textarea>
        </div>
        </form>
      </div>
    </div>
  );
}   else {
    return <Navigate to='/signin' />;
  }
};
