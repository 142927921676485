import React from "react";

const Home = ({ coins }) => {
  return (

<div className='rounded-div  my-4'>

<div className=" my-2 grid gap-2 mb-12 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">


<a href="GenerateFullBlogText" className="group flex flex-col height: 90px  max-w-xs  rounded-lg p-6 bg-white ring-1 ring-slate-900/5 shadow-lg space-y-3 hover:bg-sky-500 hover:ring-sky-500">
  <div className="flex items-center space-x-3">
    <svg className="h-6 w-6 stroke-sky-500 group-hover:stroke-white" fill="none" viewBox="0 0 24 24"></svg>
    <span className="text-3xl" role="img"> 👨‍💻</span>
    <h3 className="text-slate-900 group-hover:text-white text-sm font-semibold"> Lad Hans skrive hele din artikel!</h3>
  </div>
  <p className="text-slate-500 group-hover:text-white text-sm">Det eneste du skal fortælle ForfatterHans er en overskrift, så finder han på resten!</p>
</a>

<a href="GenerateFullBlogText" className="group flex flex-col height: 90px  max-w-xs  rounded-lg p-6 bg-white ring-1 ring-slate-900/5 shadow-lg space-y-3 hover:bg-sky-500 hover:ring-sky-500">
  <div className="flex items-center space-x-4">
    <svg className="h-6 w-6 stroke-sky-500 group-hover:stroke-white" fill="none" viewBox="0 0 24 24"></svg>
    <span className="text-3xl" role="img">  💁‍♂️</span>

    <h3 className="text-slate-900 group-hover:text-white text-sm font-semibold">Kommer snart</h3>
  </div>
  <p className="text-slate-500 group-hover:text-white text-sm">Mere info følger.</p>
</a>
<a href="GenerateFullBlogText" className="group flex flex-col height: 90px  max-w-xs  rounded-lg p-6 bg-white ring-1 ring-slate-900/5 shadow-lg space-y-3 hover:bg-sky-500 hover:ring-sky-500">
  <div className="flex items-center space-x-4">
    <svg className="h-6 w-6 stroke-sky-500 group-hover:stroke-white" fill="none" viewBox="0 0 24 24"></svg>
    <span className="text-3xl" role="img"> 💁‍♂️</span>

    <h3 className="text-slate-900 group-hover:text-white text-sm font-semibold">Kommer snart</h3>
  </div>
  <p className="text-slate-500 group-hover:text-white text-sm">Mere info følger.</p>
</a>


</div>

</div>


  );
};

export default Home;
