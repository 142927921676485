import { arrayUnion, collection, doc, getDoc, getDocs, updateDoc, where } from 'firebase/firestore';
import React from 'react'
import {useState} from 'react'
import { BounceLoader } from 'react-spinners';
import { UserAuth } from '../context/AuthContext';
import { db } from '../firebase';
import { query, orderBy, limit } from "firebase/firestore";  



const CreditOverview = () => {
    const [prompt, setPrompt] = useState(" ");
    const [isLoading, setIsLoading] = useState(false);
    const {user} = UserAuth();
    const userCollection = doc(db,'users',`${user?.email}`)
    const updateCredit = async (newCredit) => {
      await updateDoc(userCollection, {
        Credit: arrayUnion({
          createdAt: new Date(),
          CreditInThebank: newCredit,
        }),
      });
    }

    const onSubmit = () => {
        console.log("Submitting: " + prompt);
        setIsLoading(true);
        updateCredit(prompt);
        setIsLoading(false);


      };
 // console.log(PrevCredit)



  return (
    <div className="max-w-[1140px] mx-auto">
      <div className="flex justify-center items-center my-12 py-8 border border-secondary rounded-2xl shadow-xl bg-primary px-2 max-w-[500px] w-full mx-auto  ">
        <form className="w-full max-w-sm">
          Angiv hvor mange Credits du vil købe
          <div className="flex items-center border-b border-teal-500 py-2">
            <input
              className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
              type="text"
              placeholder="Eks: 100"
              onChange={(event) => setPrompt(event.target.value)}
            />
            <button
              className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
              type="button"
              onClick={onSubmit}

            >
              Tank op!
            </button>

          </div>
        </form>

      </div>
      {isLoading ?      
      <div className="flex justify-center items-center my-12 py-8 border border-secondary rounded-2xl shadow-xl bg-primary px-2 max-w-[500px] w-full mx-auto  ">
<BounceLoader color="#36d7b7" /></div>:null}

    </div>



  )
}

export default CreditOverview